import $ from 'jquery'
import 'jquery-drawer'
import {IScroll} from 'iscroll'
import './slick.min'
import './delighters'
import 'lightbox2'
import Vivus from 'vivus'

$(document).ready(function() {
    $('.drawer').drawer();
});

$(function() {
    $('a[href^="#"]').click(function() {
        var speed = 400;
        var href = $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top;
        $('body,html').animate({ scrollTop: position }, speed, 'swing');
        return false;
    });
});

$(function() {
    $(".icon01").on("click", function() {
        $(this).next("ul").slideToggle();
        if ($(".icon01").hasClass('is-open')) {
            $(".icon01").removeClass('is-open')
        } else {
            $(".icon01").addClass('is-open');
        }
    });
});

$(function() {
    $(".icon02").on("click", function() {
        $(this).next("ul").slideToggle();
        if ($(".icon02").hasClass('is-open')) {
            $(".icon02").removeClass('is-open')
        } else {
            $(".icon02").addClass('is-open');
        }
    });
});


$(function() {
    $(".icon03").on("click", function() {
        $(this).next("ul").slideToggle();
        if ($(".icon03").hasClass('is-open')) {
            $(".icon03").removeClass('is-open')
        } else {
            $(".icon03").addClass('is-open');
        }
    });
});


$(function() {
    var w = $(window).width();
    var x = 1024;
    if (w <= x) {
        $(".icon04").on("click", function() {
            $(this).next("ul").slideToggle();
            if ($(".icon04").hasClass('is-open')) {
                $(".icon04").removeClass('is-open')
            } else {
                $(".icon04").addClass('is-open');
            }
        });
    }
});


$(function() {
    var w = $(window).width();
    var x = 1024;
    if (w <= x) {
        $(".icon05").on("click", function() {
            $(this).next("ul").slideToggle();
            if ($(".icon05").hasClass('is-open')) {
                $(".icon05").removeClass('is-open')
            } else {
                $(".icon05").addClass('is-open');
            }
        });
    }
});

$(function() {
    var w = $(window).width();
    var x = 1024;
    if (w <= x) {
        $(".icon06").on("click", function() {
            $(this).next("ul").slideToggle();
            if ($(".icon06").hasClass('is-open')) {
                $(".icon06").removeClass('is-open')
            } else {
                $(".icon06").addClass('is-open');
            }
        });
    }
});

$('#svg-animation').delay(1200).queue(function(next) {
    $(this).addClass('active');
    $('#svg-animation svg').css('opacity','1');
    next();
});
$('#svg-animation').delay(2200).queue(function(next) {
    $(this).removeClass('active');
    next();
});


setTimeout(function(){
    var slideWrapper = $(".full-wide-slider"),
        iframes = slideWrapper.find('.embed-player'),
        lazyImages = slideWrapper.find('.slide-image'),
        lazyCounter = 0;

// When the slide is changing
    function changeSlide(slick, control) {
        var currentSlide, slideType, startTime, player, video;

        currentSlide = slick.find(".slick-current");
        slideType = currentSlide.attr("class").split(" ")[1];
        player = currentSlide.find("iframe").get(0);
        startTime = currentSlide.data("video-start");

        if (slideType === "video") {
            video = currentSlide.children("video").get(0);
            if (video != null) {
                if (control === "play") {
                    video.play();
                } else {
                    video.pause();
                }
            }
        }
    }

// Resize player
    function resizePlayer(iframes, ratio) {
        if (!iframes[0]) return;

        var win = $(".full-wide-slider"),
            width = win.width(),
            playerWidth,
            height = win.height(),
            playerHeight,
            ratio = ratio || 16 / 9;

        iframes.each(function() {
            var current = $(this);
            if (width / ratio < height) {
                playerWidth = Math.ceil(height * ratio);
                current.width(playerWidth).height(height).css({
                    left: (width - playerWidth) / 2,
                    top: 0
                });
            } else {
                playerHeight = Math.ceil(width / ratio);
                current.width(width).height(playerHeight).css({
                    left: 0,
                    top: (height - playerHeight) / 2
                });
            }
        });
    }

// DOM Ready
    $(function() {
        // Initialize
        slideWrapper.on("init", function(slick) {
            slick = $(slick.currentTarget);
            setTimeout(function() {
                changeSlide(slick, "play");
            }, 1000);
            resizePlayer(iframes, 16 / 9);
        });
        slideWrapper.on("beforeChange", function(event, slick) {
            slick = $(slick.$slider);
            changeSlide(slick, "pause");
        });
        slideWrapper.on("afterChange", function(event, slick) {
            slick = $(slick.$slider);
            changeSlide(slick, "play");
        });
        slideWrapper.on("lazyLoaded", function(event, slick, image, imageSource) {
            lazyCounter++;
            if (lazyCounter === lazyImages.length) {
                lazyImages.addClass('show');
                slideWrapper.slick("slickPlay");
            }
        });

        var $slider = $('.full-wide-slider');
        var $slide = $slider.children();
        var slideLen = $slide.length;

        //start the slider
        slideWrapper.slick({
            fade: true,
            autoplay: true,
            autoplaySpeed: 6500,
            draggable: false,
            pauseOnFocus: false,
            pauseOnHover: false,
            lazyLoad: "progressive",
            speed: 600,
            arrows: false,
            dots: false,
            infinite: false,
            cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)"
        })
            .on('afterChange', function() {
                var $self = $(this);
                if ((slideLen - 1) <= $self.slick('slickCurrentSlide')) {
                    $self.slick('slickSetOption', 'autoplay', false);
                }
            })

            .on("afterChange", function(event, slick, currentSlide, nextSlide) {
                switch (currentSlide) {
                    case 1:
                        // 2枚目のスライド
                        $(this).slick("slickSetOption", "autoplaySpeed", 19500);
                        break;
                    default:
                        // その他のスライド
                        $(this).slick("slickSetOption", "autoplaySpeed", 6500);
                        break;
                }
            });

    });

// Resize event
    $(window).on("resize.slickVideoPlayer", function() {
        resizePlayer(iframes, 16 / 9);
    });

},3100);

if(document.getElementsByClassName('cls-3').length){
    setTimeout(function() {
        var cls = document.querySelectorAll('.cls-3');
        var anime = function(){
            new Vivus('svg-anime01', {type: 'scenario-sync',duration: 8, forceRender: false ,animTimingFunction:Vivus.EASE},function(){

            });
        }
        anime();
    }, 5000);
}